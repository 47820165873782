<template>
    <div class="topicList">
        <div class="topicItem" v-for="(item,index) in topicList" :key="index" @click="tagJump(item)">
            <div class="topicInfo">
                <div class="portrait">
                    <ImgDecypt class="avatarImg" :src="item.portrait" round />
                </div>
                <div class="topicLabel">
                    <div class="topicTitle">
                        <span>#{{item.name}}</span>
                    </div>
                    <div class="topicDtails">
                        <span>{{item.desc}}</span>
                    </div>
                </div>
            </div>
            <div class="btn" @click.stop="focus(item)">
                <div class="focusBtn" v-if="!item.hasFollow">
                    <svg-icon icon-class="btnAdd" class="btnIcon"></svg-icon>
                    <span>
                    关注
                </span>
                </div>
                <div class="alreadyFocus" v-else>
                <span>
                    已关注
                </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImgDecypt from "@/components/ImgDecypt";
import {collectTag} from "@/api/community";
import {careOrcancle} from "@/api/user";
import {Toast} from "vant";

export default {
    name: "TopicList",
    components:{
        ImgDecypt
    },
    props:{
        topicList:{
            type: Array
        }
    },methods:{
        async focus(item) {
            /**
             * 关注/取消关注 博主
             */
            let req = {
                followUID: item.uid,
                isFollow: !item.hasFollow,
            }
            let res = await this.$Api(careOrcancle, req);

            if (res.code === 200) {
                Toast(req.isFollow ? "关注成功" : "取消成功")
                item.hasFollow = req.isFollow;
                return;
            }
            Toast(res.tip || `${req.isFollow ? '关注失败' : '取消关注失败'}`);
        },tagJump(item){
            /**
             * 博主详情
             */
            this.$router.push({
                path: "/userHomePage",
                query: {
                    uid: item.uid
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.topicList{
    .topicItem{
        display: flex;
        justify-content: space-between;
        align-items: end;
        padding: 0 12px;
        margin-top: 12px;

        .topicInfo{
            display: flex;
            align-items: center;

            .portrait{
                width: 60px;
                height: 60px;
                //border-radius: 50%;
                //background: rgb(230,230,230);

                .avatarImg{
                    border-radius: 4px;

                    /deep/ .van-image__img{
                        border-radius: 4px;
                    }
                }
            }

            .topicLabel{
                padding-left: 12px;

                .topicTitle{
                    font-size: 16px;
                    color: #333333;
                    font-weight: 500;
                }

                .topicDtails{
                    margin-top: 6px;
                    font-size: 14px;
                    color: #999999;
                }
            }
        }


        .btn{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 6px;

            .btnIcon{
                width: 12px;
                height: 12px;
                margin-right: 4px;
            }

            .focusBtn{
                width: 58px;
                height: 22px;
                font-size: 12px;
                //padding: 3px 10px;
                //background: rgb(113, 69, 231);
                border-radius: 12.5px;
                color: #94D6DA;
                border: solid #94D6DA 1px ;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .alreadyFocus{
                width: 60px;
                height: 24px;
                font-size: 12px;
                //padding: 3px 12px;
                background: #B3B3B3;
                border-radius: 12.5px;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
                //border: solid #94D6DA 2px ;
            }
        }
    }
}
</style>